// LandingPage.jsx
import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
// import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer"; // Import the Footer component
import "../styles/LandingPage.css";

const images = [
  "/IMG_2635.JPG",
  "/IMG_2645.JPG",
  "/Screenshot 2024-10-13 151545.png",
  "/USflag2.png",
  "/Screenshot 2024-10-13 150628.png",
];

const LandingPage = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(true);

  // Fetch announcements from Supabase
  const fetchAnnouncements = async () => {
    const { data, error } = await supabase
      .from("announcements")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) {
      console.error("Error fetching announcements:", error);
    } else {
      setAnnouncements(data);
    }
  };

  useEffect(() => {
    fetchAnnouncements();

    const interval = setInterval(() => {
      setFade(false); // Start fading out
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        setFade(true); // Fade back in
      }, 500); // Fade-out duration in milliseconds
    }, 10000); // Change image every 15 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="landing-container"
      style={{
        backgroundImage: "url('/Untitled.png2.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <Header />
      <NavBar showHome={false} />
      <div className="landing-content">
        {/* Image Slideshow with Fade Transition */}
        <section className="landing-image">
          <img
            src={images[currentImage]}
            alt="Storefront of Lazy J Meats"
            className={`slideshow-image ${fade ? "fade-in" : "fade-out"}`}
          />
        </section>

        {/* News feed section */}
        <section className="news-feed">
          <h2>Announcements</h2>
          {announcements.length > 0 ? (
            announcements.map((item) => (
              <div key={item.id} className="announcement-item">
                <p>{item.text}</p>
                {item.imageUrl && (
                  <img src={item.imageUrl} alt="Announcement" />
                )}
              </div>
            ))
          ) : (
            <p>No announcements available right now. Check back later!</p>
          )}
        </section>
      </div>
      {/* <Link to="/login" className="admin-button">
        Admin Portal
      </Link> */}
      <Footer /> {/* Add Footer at the bottom */}
    </div>
  );
};

export default LandingPage;
