// import React from 'react';
import NavBar from "./NavBar";
import Footer from "./Footer";
import Header from "./Header"; // Import Header component
import "../styles/ContactUsPage.css";

const ContactUsPage = () => {
  return (
    <div>
      <Header /> {/* Include the Header component */}
      <NavBar showHome={true} /> {/* Include the NavBar with Home button */}
      <h1>Contact Us</h1>
      {/* <p>Reach out to us via social media, email, or visit our store in Payson, UT!</p> */}
      <div className="contact-info">
        <h2>Our Location</h2>
        <p>95 S Main St, Payson, UT 84651</p>
        <p>Phone: (801) 465-3127</p>
        <p>Business Hours:</p>
        <ul>
          <li>Monday-Friday: 9 AM–5 PM</li>
          <li>Saturday: 9 AM–12 PM</li>
          <li>Sunday: Closed</li>
        </ul>

        <h2>Follow Us</h2>
        <ul className="social-links">
          <li>
            <a
              href="https://www.instagram.com/lazyjmeats?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/dixons.meats/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@lazyjmeats?lang=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              TikTok
            </a>
          </li>
        </ul>
      </div>
      <Footer /> {/* Place the Footer component here */}
    </div>
  );
};

export default ContactUsPage;
