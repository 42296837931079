import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import NavBar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer"; // Import Footer component
import "../styles/MenuPage.css";

const MenuPage = () => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      const { data, error } = await supabase.from("menu").select("*");

      if (error) {
        console.error("Error fetching menu items:", error);
      } else {
        setMenuItems(data);
      }
    };

    fetchMenuItems();
  }, []);

  // Function to render menu items by both category and type
  const renderItemsByCategoryAndType = (category, type) => {
    return menuItems
      .filter((item) => item.category === category && item.type === type)
      .map((item) => (
        <div key={item.id} className="menu-item">
          {item.name} - ${item.price} per {item.unit}
        </div>
      ));
  };

  return (
    <div className="menu-container">
      {/* Background image */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url('/USflag2.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
          opacity: 0.2,
        }}
      />
      <Header />
      <NavBar showHome={true} />
      <h1>Menu</h1>
      <p>**PRICES SUBJECT TO CHANGE**</p>
      {/* Displaying Menu Items */}
      <div className="menu-section-container">
        {/* Retail Section */}
        <div className="menu-section">
          <h2>Retail</h2>
          <div className="meat-category">
            <h3>Beef</h3>
            {renderItemsByCategoryAndType("Retail", "Beef")}
          </div>
          <div className="meat-category">
            <h3>Pork</h3>
            {renderItemsByCategoryAndType("Retail", "Pork")}
          </div>
          <div className="meat-category">
            <h3>Chicken</h3>
            {renderItemsByCategoryAndType("Retail", "Chicken")}
          </div>
          <div className="meat-category">
            <h3>Steak</h3>
            {renderItemsByCategoryAndType("Retail", "Steak")}
          </div>
          <div className="meat-category">
            <h3>Roast</h3>
            {renderItemsByCategoryAndType("Retail", "Roast")}
          </div>
        </div>

        {/* Game Section */}
        <div className="menu-section">
          <h2>Game</h2>
          <div className="meat-category">
            <h3>Elk</h3>
            {renderItemsByCategoryAndType("Game", "Elk")}
          </div>
          <div className="meat-category">
            <h3>Deer</h3>
            {renderItemsByCategoryAndType("Game", "Deer")}
          </div>
          <div className="meat-category">
            <h3>Sheep</h3>
            {renderItemsByCategoryAndType("Game", "Sheep")}
          </div>
        </div>

        {/* Processing Section */}
        <div className="menu-section">
          <h2>Processing</h2>
          <div className="meat-category">
            <h3>Beef</h3>
            {renderItemsByCategoryAndType("Processing", "Beef")}
          </div>
          <div className="meat-category">
            <h3>Pig</h3>
            {renderItemsByCategoryAndType("Processing", "Pig")}
          </div>
          <div className="meat-category">
            <h3>Lamb</h3>
            {renderItemsByCategoryAndType("Processing", "Lamb")}
          </div>
        </div>
      </div>
      <Footer /> {/* Place the Footer component here */}
    </div>
  );
};

export default MenuPage;
