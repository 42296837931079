// Locally 
// import { createClient } from '@supabase/supabase-js';

// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL; // Your Supabase URL
// const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY; // Your Supabase anon public key

// console.log(process.env.REACT_APP_SUPABASE_URL);
// console.log(process.env.REACT_APP_SUPABASE_ANON_KEY);


// export const supabase = createClient(supabaseUrl, supabaseAnonKey);


// Deployed file

// import { createClient } from "@supabase/supabase-js";

// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
// const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

// if (!supabaseUrl || !supabaseKey) {
//   throw new Error("supabaseUrl and supabaseKey are required");
// }

// export const supabase = createClient(supabaseUrl, supabaseKey);



// Attepmt to combine them to make it work even when deployed or when it local

import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY || process.env.REACT_APP_SUPABASE_ANON_KEY; // Use a consistent name for both

if (!supabaseUrl || !supabaseKey) {
  throw new Error('supabaseUrl and supabaseKey are required');
}

export const supabase = createClient(supabaseUrl, supabaseKey);
