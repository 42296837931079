import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/NavBar.css";

const NavBar = ({ showHome }) => {
  const location = useLocation();
  const isLandingPage = location.pathname === "/";

  return (
    <nav className="navbar">
      <ul className="nav-links">
        {showHome && (
          <li>
            <Link to="/" className="nav-item home-link">
              Home
            </Link>
          </li>
        )}
        <li>
          <Link
            to="/about"
            className={`nav-item ${
              isLandingPage ? "landing-about-link" : "page-about-link"
            }`}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            to="/menu"
            className={`nav-item ${
              isLandingPage ? "landing-menu-link" : "page-menu-link"
            }`}
          >
            Menu
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className={`nav-item ${
              isLandingPage ? "landing-contact-link" : "page-contact-link"
            }`}
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
