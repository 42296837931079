import React from "react";
import NavBar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer"; // Import the Footer component
import "../styles/AboutUsPage.css";

const AboutUsPage = () => {
  return (
    <div
      className="about-container"
      style={{
        backgroundImage:
          "url('/Untitled.png3.png')" /* Path to the image in the public folder */,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed" /* Fixes the background as you scroll */,
        backgroundPosition: "center",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Header />
      <NavBar showHome={true} />{" "}
      {/* Include the Home button on the About Us page */}
      <section className="about-content">
        <h1>A Passion for Agriculture and Quality Meats</h1>
        <p>
          Established in 2020, Lazy J Meats is proud to be a 5th generation
          ranch. We are committed to producing hormone-free, antibiotic-free,
          and vaccine-free corn and grass-finished livestock, providing your
          family with some of the highest nutritional meat available today.
        </p>
        <p>
          Our mission is rooted in sustainability, community, and the highest
          quality standards. We cherish our strong connections with local
          farmers and are passionate about maintaining these relationships. From
          start to finish, our livestock is raised with the utmost care and
          attention to detail to ensure the best quality meats for our
          customers.
        </p>
        <p>
          We are also dedicated to bringing awareness to the challenges facing
          not just local farmers in our community, but small farmers and
          ranchers across the country. We stand firm against the growing threat
          of corporate farms and are committed to supporting independent farming
          operations nationwide.
        </p>
        <p>
          Our passion for agriculture drives us to always act with honesty,
          integrity, and exceptional customer service. We are open to
          collaborations and encourage anyone interested to reach out. Feel free
          to contact us through our{" "}
          <a href="/contact" className="link">
            Contact Us
          </a>{" "}
          page.
        </p>
        <p>
          While we value our customers immensely, please note that we do not
          offer shipping services at this time. All purchases must be made
          locally.
        </p>
        <p>
          We are proud partners of{" "}
          <a
            href="https://www.fromthefarm.org"
            className="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            FromTheFarm.org
          </a>
          , a website dedicated to helping consumers connect with local ranchers
          across the country.
        </p>

        {/* Image Section */}
        <div className="image-gallery">
          <img
            src="/Screenshot 2024-10-13 150628.png"
            alt="Aerial shot of Lazy J Meats ranch"
            className="about-image"
          />
          <img
            src="/IMG_2635.JPG"
            alt="View of cattle grazing"
            className="about-image"
          />
          <img
            src="/Screenshot 2024-10-13 151545.png"
            alt="The team at Lazy J Meats"
            className="about-image"
          />
        </div>
      </section>
      <Footer /> {/* Place the Footer component here */}
    </div>
  );
};

export default AboutUsPage;
