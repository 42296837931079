// src/components/Header.jsx
import React from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css";

const Header = () => {
  return (
    <header className="header">
      <h1 className="company-name">
        <Link to="/" className="name-link">
          Lazy J Meats
        </Link>{" "}
        {/* Clickable Name */}
      </h1>
      <p className="est">EST. 2020</p>
    </header>
  );
};

export default Header;
