import React from 'react';
import { Route, Routes } from 'react-router-dom';  // No need to import BrowserRouter here
import './styles/App.css';  // Correct CSS import for App styles
import LandingPage from './components/LandingPage';
import AboutUsPage from './components/AboutUsPage';
import MenuPage from './components/MenuPage';
import ContactUsPage from './components/ContactUsPage';
import LoginPage from './components/LoginPage';  // New Login Page
import AdminPage from './components/AdminPage';  // New Admin Page
import Footer from "./components/Footer";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/login" element={<LoginPage />} />  {/* New login route */}
        <Route path="/admin" element={<AdminPage />} />  {/* New admin route */}
        <Route path="/footer" element={<Footer />} />
      </Routes>
    </div>
  );
}

export default App;
