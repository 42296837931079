import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Autosuggest from "react-autosuggest";
import "../styles/AdminPanel.css";

const predefinedItems = [
  "Ground Beef",
  "Beef Patties",
  "Baconator GB",
  "Beef Jerky 16oz",
  "Beef Jerky 8oz",
  "Steak Bites 16oz",
  "Chuck",
  "Flat Iron",
  "Flank",
  "Hanger",
  "New York",
  "Porterhouse",
  "Ribeye",
  "Sirloin",
  "Skirt",
  "T-Bone",
  "Tenderloin Filet",
  "Tomahawk",
  "Beef Tenderloin",
  "Brisket",
  "Chuck Rst",
  "Eye of Round",
  "Picanha",
  "Prime Rib Rst",
  "Sirloin Tip Rst",
  "Tri Tip",
  "Beef Short Ribs",
  "Beef Dino Ribs",
  "Bacon",
  "Sausage",
  "Sausage Links",
  "Ham Stk",
  "Pork Butt Rst",
  "Pork Loin Rst",
  "Pork Chops",
  "Baby Back Ribs",
  "St. Louis Spareribs",
  "Chicken Breast",
  "Chicken Thighs",
  "Chicken Wings",
  "Hot Dogs",
];

const AdminPage = () => {
  const [name, setName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("Retail");
  const [type, setType] = useState("Beef");
  const [unit, setUnit] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [announcement, setAnnouncement] = useState("");
  const [announcements, setAnnouncements] = useState([]);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const fetchMenuItems = async () => {
    const { data, error } = await supabase.from("menu").select("*");
    if (error) console.error("Error fetching menu items:", error);
    else setMenuItems(data);
  };

  const fetchAnnouncements = async () => {
    const { data, error } = await supabase
      .from("announcements")
      .select("*")
      .order("created_at", { ascending: false });

    if (error) console.error("Error fetching announcements:", error);
    else setAnnouncements(data);
  };

  useEffect(() => {
    fetchMenuItems();
    fetchAnnouncements();
  }, []);

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : predefinedItems.filter((item) =>
          item.toLowerCase().startsWith(inputValue)
        );
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (event, { newValue }) => {
    setName(newValue);
  };

  const inputProps = {
    placeholder: "Enter menu item...",
    value: name,
    onChange: onChange,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if price is empty or a valid number
    const parsedPrice = price ? parseFloat(price) : null;
    if (parsedPrice !== null && (isNaN(parsedPrice) || parsedPrice <= 0)) {
      alert(
        "Please enter a valid price greater than 0, or leave it blank for 'Quote Only'"
      );
      return;
    }

    const { error } = await supabase
      .from("menu")
      .insert([{ name, price: parsedPrice, type, category, unit }]);

    if (error) console.error("Error adding menu item:", error);
    else {
      setName("");
      setPrice("");
      setType("Beef");
      setCategory("Retail");
      setUnit("");
      alert("Menu item added successfully");
      fetchMenuItems();
    }
  };

  const handleDelete = async (id) => {
    const { error } = await supabase.from("menu").delete().eq("id", id);
    if (error) console.error("Error deleting menu item:", error);
    else {
      alert("Menu item deleted");
      fetchMenuItems();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleAnnouncementSubmit = async (e) => {
    e.preventDefault();
    let imageUrl = "";
    const timestamp = new Date().toLocaleString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    if (image) {
      const uniqueId = `${Date.now()}-${image.name}`;
      const { data, error } = await supabase.storage
        .from("announcements")
        .upload(`public/${uniqueId}`, image);

      if (error) {
        console.error("Error uploading image:", error);
        return;
      }

      const s3Endpoint = process.env.REACT_APP_SUPABASE_URL;
      imageUrl = `${s3Endpoint}/storage/v1/object/public/announcements/${data.path}`;
    }

    const { error: announcementError } = await supabase
      .from("announcements")
      .insert([{ text: announcement, imageUrl, timestamp }]);

    if (announcementError)
      console.error("Error adding announcement:", announcementError);
    else {
      setAnnouncement("");
      setImage(null);
      setImagePreview(null);
      alert("Announcement added successfully");
      fetchAnnouncements();
    }
  };

  const handleDeleteAnnouncement = async (id) => {
    const { data: announcement, error: fetchError } = await supabase
      .from("announcements")
      .select("imageUrl")
      .eq("id", id)
      .single();

    if (fetchError) {
      console.error("Error fetching announcement:", fetchError);
      return;
    }

    if (announcement.imageUrl) {
      const fileName = announcement.imageUrl.split("/").pop();
      const { error: deleteError } = await supabase.storage
        .from("announcements")
        .remove([`public/${fileName}`]);

      if (deleteError) {
        console.error("Error deleting image:", deleteError);
        return;
      }
    }

    const { error: deleteAnnouncementError } = await supabase
      .from("announcements")
      .delete()
      .eq("id", id);

    if (deleteAnnouncementError) {
      console.error("Error deleting announcement:", deleteAnnouncementError);
    } else {
      alert("Announcement and associated image deleted successfully");
      fetchAnnouncements();
    }
  };

  return (
    <div className="admin-panel-container">
      <NavBar showHome={true} />
      <h1>Admin Panel</h1>
      <hr className="section-separator" />

      <form onSubmit={handleSubmit} className="menu-form">
        <div>
          <label>Category:</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="Retail">Retail</option>
            <option value="Game">Game</option>
            <option value="Processing">Processing</option>
          </select>
        </div>

        <div>
          <label>Type of Meat:</label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          >
            <option value="Beef">Beef</option>
            <option value="Pork">Pork</option>
            <option value="Chicken">Chicken</option>
            <option value="Steak">Steak</option>
            <option value="Roast">Roast</option>
            <option value="Pig">Pig</option>
            <option value="Lamb">Lamb</option>
            <option value="Elk">Elk</option>
            <option value="Deer">Deer</option>
            <option value="Sheep">Sheep</option>
          </select>
        </div>

        <div>
          <label>Item Name:</label>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={(suggestion) => suggestion}
            renderSuggestion={(suggestion) => <div>{suggestion}</div>}
            inputProps={inputProps}
          />
        </div>

        <div>
          <label>Price:</label>
          <input
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>

        <div>
          <label>Unit:</label>
          <select value={unit} onChange={(e) => setUnit(e.target.value)}>
            <option value="">None</option>
            <option value="lb">lb</option>
            <option value="oz">oz</option>
            <option value="pkg">pkg</option>
          </select>
        </div>

        <div>
          <button type="submit">Add Item</button>
        </div>
      </form>

      <hr className="section-separator" />
      <h2 className="menu-title">Current Menu Items</h2>
      <div className="menu-items-list">
        <div className="menu-section">
          <h3>Retail</h3>
          {menuItems
            .filter((item) => item.category === "Retail")
            .map((item) => (
              <div key={item.id} className="menu-item">
                {item.name} - ${item.price} per {item.unit}
                <button
                  className="delete-button"
                  onClick={() => handleDelete(item.id)}
                >
                  X
                </button>
              </div>
            ))}
        </div>
        <div className="menu-section">
          <h3>Game</h3>
          {menuItems
            .filter((item) => item.category === "Game")
            .map((item) => (
              <div key={item.id} className="menu-item">
                {item.name} - ${item.price} per {item.unit}
                <button
                  className="delete-button"
                  onClick={() => handleDelete(item.id)}
                >
                  X
                </button>
              </div>
            ))}
        </div>
        <div className="menu-section">
          <h3>Processing</h3>
          {menuItems
            .filter((item) => item.category === "Processing")
            .map((item) => (
              <div key={item.id} className="menu-item">
                {item.name} - ${item.price} per {item.unit}
                <button
                  className="delete-button"
                  onClick={() => handleDelete(item.id)}
                >
                  X
                </button>
              </div>
            ))}
        </div>
      </div>

      <hr className="section-separator" />
      <div className="announcement-section">
        <h2>Add Announcement</h2>
        <form onSubmit={handleAnnouncementSubmit}>
          <label>Announcement:</label>
          <textarea
            value={announcement}
            onChange={(e) => setAnnouncement(e.target.value)}
            rows="4"
            required
          />
          <label>Optional Image:</label>
          <input type="file" onChange={handleImageChange} />
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Preview"
              style={{ width: "100px", marginTop: "10px" }}
            />
          )}
          <button type="submit">Post Announcement</button>
        </form>
      </div>

      <div className="announcement-list">
        {announcements.map((item) => (
          <div key={item.id} className="announcement-item">
            <p>{item.text}</p>
            {item.imageUrl && <img src={item.imageUrl} alt="Announcement" />}
            <p className="timestamp">Posted on: {item.timestamp}</p>
            <button
              className="delete-button"
              onClick={() => handleDeleteAnnouncement(item.id)}
            >
              X
            </button>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default AdminPage;
